import React from "react"
import SEO from "../../components/seo"
import { Skeleton } from "../../components/layout/Skeleton"
import CategoryLabel from "../../components/CategoryLabel"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"

interface Author {
  id: string
  biography: string
  name: string
  imageFile: { childImageSharp: { fluid: any } }
}

const AuthorListItem = styled.div`
  margin: 20px;

  & img {
    width: 90%;
    height: 90%;
    max-width: 150px;
    max-height: 150px;
    border-radius: 50%;
    vertical-align: middle;
    border-style: none;
  }
`

export default function Team({
  data,
}: {
  data: {
    allAuthor: {
      nodes: Author[]
    }
  }
}) {
  return (
    <>
      <SEO
        title={`Redakcja | Nasze Pierwsze Dziecko`}
        description={
          "Lista redaktorek piszących artykuły publikowane w serwisie Nasze Pierwsze Dziecko"
        }
      />
      <Skeleton banner={false}>
        <CategoryLabel title={"Redakcja"} />
        {data.allAuthor.nodes.map(author => (
          <AuthorListItem>
            <div className={"columns"}>
              <div className={"column is-2"}>
                <Img
                  style={{ maxWidth: "150px", margin: "0 auto" }}
                  alt={author.name}
                  fluid={author.imageFile.childImageSharp.fluid}
                />
              </div>
              <div className={"column is-10"}>
                <h2 className={"is-2 title"}>{author.name}</h2>
                <p className={"subtitle"}>{author.biography}</p>
              </div>
            </div>
          </AuthorListItem>
        ))}
      </Skeleton>
    </>
  )
}

export const query = graphql`
  query {
    allAuthor {
      nodes {
        id
        biography
        imageFile {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        name
      }
    }
  }
`
